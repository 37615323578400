class HeroBannerWidget extends Widget {
    constructor() {
        super(".hero-banner-search")
        const item_search = document.querySelector(".mdn-Menu-top-list-item-search")
        const search_input = document.querySelector("#qelp-search");
        const close = document.querySelector('.mdn-Menu-top-list-item-search .mdn-Input-action[data-mdn-action="clear-field"]');
        this.element.addEventListener("click", e=> {
            e.preventDefault();
            item_search.classList.add("mdn-is-active")
        });
        close.addEventListener("click", e=> {
            item_search.classList.remove("mdn-is-active")
        })
    }
}
window.addEventListener("eHelpFrameworkReady", e => {
    eHelpFramework.widgets.push(new HeroBannerWidget())
});